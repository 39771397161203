import React, { FC, FocusEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import styled from 'styled-components';
import Text from 'components/Text';
import { Loader, LoadingPage, PageContainer, Seo, TextInput } from 'components';
import Header from 'components/Header';
import { tablet, useQuery } from 'styles/breakpoints';
import { Analytics } from 'apis/Analytics';
import { AppState } from 'state/types';
import { useRouter } from 'apis/history';
import { config } from 'config';
import Button from 'components/PrimaryButton';
import { usePageView, useQuizData } from 'utils/hooks';
import { Quiz } from 'types/quiz';
import { klaviyoLogUserHasPaidAndRegistered } from 'utils/klavyio';
import RegisterCompleted from './components/RegisterCompleted';
import Tracking from 'utils/tracking';
import { normalizeStates } from 'utils/localization';
import { useForm } from 'react-hook-form';
import Show from 'assets/icons/show-eye.svg';
import Hide from 'assets/icons/hide-eye.svg';
import DefaultX from 'assets/icons/default-x.svg';
import GreenCheck from 'assets/icons/green-check.svg';
import RedX from 'assets/icons/red-x.svg';

interface FormData {
  email: string;
  password?: string;
  repeatedPassword?: string;
}

interface InputProps {
  focused: boolean;
  hasError: boolean;
  isValid: boolean;
}

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required('Password is required')
    .min(6, 'Password must be at least 6 characters long'),
  repeatedPassword: Yup.string()
    .required('Please confirm your password')
    .oneOf([Yup.ref('password')], 'Passwords must match'),
});

const Register: FC = () => {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [isRegisterComplete, setIsRegisterComplete] = useState<boolean>(false);
  const [passwordTouched, setPasswordTouched] = useState(false);
  const [repeatPasswordTouched, setRepeatPasswordTouched] = useState(false);

  const {
    quiz_answers,
    user,
    code,
    selected_plans,
    geolocation,
    upsell_products,
  } = useSelector((state: AppState) => state.user);
  const upgradeSequences = useQuizData('upgradeSequences');

  const data = useQuizData('register');

  useEffect(() => {
    // Add event listener on mount
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Remove event listener on cleanup
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const handleBeforeUnload = event => {
    event.preventDefault(); // If you prevent default behavior in Mozilla Firefox
    event.returnValue = 'Are you sure you want to leave?'; // Chrome requires returnValue to be set
  };

  usePageView({
    country: geolocation?.iso_country?.toLowerCase() || 'no-country-fallback',
    state: normalizeStates(
      geolocation?.iso_country || '',
      geolocation?.iso_state || '',
    ),
    city: encodeURIComponent(
      geolocation?.city?.toLowerCase().replace(/[^a-z0-9]/g, '') || '',
    ),
    email: user?.email.trim() || 'no-email-fallback',
    gender: quiz_answers?.gender ? quiz_answers?.gender[0] : null,
    client_code: code,
  });

  const { goToUpgrade, goToSuccess } = useRouter();

  const quiz = new URLSearchParams(location.search).get('qz') ?? Quiz.Main;
  const caseParam = new URLSearchParams(location.search).get('case');
  const isFromSupportCase = caseParam === 'support';

  const purchasedCrossSell = upsell_products.find(item =>
    item.key.includes('walking-yoga'),
  )?.is_subscription;

  const isSubPurchased =
    upsell_products.find(
      item =>
        item.key.includes('lasting-change') &&
        !item.key.includes('walking-yoga'),
    )?.is_subscription ||
    selected_plans?.find(
      item =>
        item.key.includes('lasting-change') &&
        !item.key.includes('walking-yoga'),
    )?.is_subscription ||
    upsell_products.find(
      item =>
        item.key.includes('upsell-app-half') &&
        !item.key.includes('walking-yoga'),
    )?.is_subscription;

  const variant = () => {
    if (purchasedCrossSell && isSubPurchased) return 'combo';
    if (purchasedCrossSell) return 'WY';
    if (isSubPurchased) return 'LC';
    return 'LC';
  };

  const {
    register,
    handleSubmit,
    trigger,
    getValues,
    setValue,
    setError,
    formState: { errors, touchedFields, dirtyFields },
  } = useForm<FormData>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      password: '',
      repeatedPassword: '',
    },
    mode: 'onChange',
  });

  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const toggleRepeatPasswordVisibility = () =>
    setShowRepeatPassword(!showRepeatPassword);

  const onSubmit = async (data: FormData) => {
    setLoading(true);

    const isPasswordValid = await trigger('password');
    const isRepeatPasswordValid = await trigger('repeatedPassword');

    // Set errors if validation fails
    if (!isPasswordValid) {
      setError('password', {
        type: 'manual',
        message: 'Password is invalid or missing',
      });
    }

    if (!isRepeatPasswordValid) {
      setError('repeatedPassword', {
        type: 'manual',
        message: 'Repeated password is invalid or missing',
      });
    }

    // Stop submission if validation fails
    if (!isPasswordValid || !isRepeatPasswordValid) {
      setLoading(false);
      return;
    }

    Tracking.trackCTAButton(location.pathname);

    const userData = {
      email: user?.email,
      password: data.password,
    };

    const funnelData = {
      code: code ?? '',
      quizAnswers: quiz_answers ?? {},
      selectedPlans: selected_plans ?? {},
      quiz,
      onboardingBooked: true,
    };

    const userRTDBProperties = {
      userDetails: {
        isQuizComplete: false,
        projectOrigin: 'LC',
        code: code,
        gender: quiz_answers?.gender || 'female',
        unitsOfMeasure: 'imperial',
      },
    };

    try {
      if (isSubPurchased) {
        await axios.post(config.FIREBASE_REGISTER_URL, {
          user: userData,
          funnel: funnelData,
        });
      }

      if (purchasedCrossSell) {
        await axios.post(config.WY_REGISTER_URL, {
          userCredentials: userData,
          userRTDBProperties,
        });
      }

      Tracking.trackRegistration(funnelData.code);
      klaviyoLogUserHasPaidAndRegistered(user?.email || 'no-email');
    } catch (error) {
      console.error(error);

      setLoading(false);
    }
    if (!isFromSupportCase) {
      setLoading(false);
      goToSuccess();
      return;
    }

    setIsRegisterComplete(true);
    setLoading(false);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordTouched(true);
    setValue('password', e.target.value, { shouldValidate: true });
    trigger('password');
    if (getValues('repeatedPassword')) {
      trigger('repeatedPassword');
    }
  };

  const handleRepeatPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRepeatPasswordTouched(true);
    setValue('repeatedPassword', e.target.value, { shouldValidate: true });
    trigger('repeatedPassword');
    if (getValues('password')) {
      trigger('password');
    }
  };

  return (
    <>
      <Seo />
      <Header logoVariant="center" sticky={false} />
      <PageContainer bgColor="none">
        {isRegisterComplete ? (
          <RegisterCompleted
            successTitle={data?.[variant()]?.successTitle}
            successInfo={data?.[variant()]?.successInfo}
            variant={variant()}
          />
        ) : (
          <ContentContainer>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Title
                dangerouslySetInnerHTML={{ __html: data?.[variant()]?.title }}
              />
              <InputWrapper>
                <Label>{data?.emailLabel}</Label>
                <UserEmail>{user?.email ?? ''}</UserEmail>
                <Label>{data?.passwordLabel}</Label>
                <InputContainer>
                  <Input
                    type={showPassword ? 'text' : 'password'}
                    {...register('password')}
                    hasError={
                      getValues('password')
                        ? touchedFields.password && !!errors.password
                        : !!errors.password
                    }
                    isValid={
                      passwordTouched &&
                      !errors.password &&
                      getValues('password')
                    }
                    onFocus={() => setPasswordTouched(true)}
                    onChange={handlePasswordChange}
                  />
                  <ToggleButton
                    type="button"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? <Hide /> : <Show />}
                  </ToggleButton>
                </InputContainer>
                <Error
                  hasErrors={
                    getValues('password')
                      ? touchedFields.password && !!errors.password
                      : !!errors.password
                  }
                  isValid={
                    passwordTouched && !errors.password && getValues('password')
                  }
                >
                  {getValues('password') && !!errors.password ? (
                    <RedX />
                  ) : !errors.password && getValues('password') ? (
                    <GreenCheck />
                  ) : (
                    <DefaultX />
                  )}
                  <ErrorText
                    hasErrors={
                      getValues('password')
                        ? touchedFields.password && !!errors.password
                        : !!errors.password
                    }
                    isValid={
                      passwordTouched &&
                      !errors.password &&
                      getValues('password')
                    }
                  >
                    {data?.requirePasswordError}
                  </ErrorText>
                </Error>
              </InputWrapper>

              <InputWrapper>
                <Label>{data?.confirmPasswordLabel}</Label>
                <InputContainer>
                  <Input
                    type={showRepeatPassword ? 'text' : 'password'}
                    {...register('repeatedPassword')}
                    hasError={
                      getValues('repeatedPassword')
                        ? touchedFields.repeatedPassword &&
                          !!errors.repeatedPassword
                        : !!errors.repeatedPassword
                    }
                    isValid={
                      repeatPasswordTouched &&
                      !errors.repeatedPassword &&
                      getValues('repeatedPassword')
                    }
                    onFocus={() => setRepeatPasswordTouched(true)}
                    onChange={handleRepeatPasswordChange}
                  />
                  <ToggleButton
                    type="button"
                    onClick={toggleRepeatPasswordVisibility}
                  >
                    {showRepeatPassword ? <Hide /> : <Show />}
                  </ToggleButton>
                </InputContainer>
                <Error
                  hasErrors={
                    getValues('repeatedPassword')
                      ? touchedFields.repeatedPassword &&
                        !!errors.repeatedPassword
                      : !!errors.repeatedPassword
                  }
                  isValid={
                    repeatPasswordTouched &&
                    !errors.repeatedPassword &&
                    getValues('repeatedPassword')
                  }
                >
                  {getValues('repeatedPassword') &&
                  !!errors.repeatedPassword ? (
                    <RedX />
                  ) : repeatPasswordTouched &&
                    !errors.repeatedPassword &&
                    getValues('repeatedPassword') ? (
                    <GreenCheck />
                  ) : (
                    <DefaultX />
                  )}
                  <ErrorText
                    isValid={
                      repeatPasswordTouched &&
                      !errors.repeatedPassword &&
                      getValues('repeatedPassword')
                    }
                    hasErrors={
                      getValues('repeatedPassword')
                        ? touchedFields.repeatedPassword &&
                          !!errors.repeatedPassword
                        : !!errors.repeatedPassword
                    }
                  >
                    {data?.passwordMismatchError}
                  </ErrorText>
                </Error>
              </InputWrapper>

              <StyledButton type="submit" loading={loading} disabled={loading}>
                {data?.submitBtnTitle}
              </StyledButton>
            </Form>
          </ContentContainer>
        )}
      </PageContainer>
    </>
  );
};

export default Register;

const InputWrapper = styled.div`
  padding-bottom: 1rem;
  width: 100%;
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: flex-start;
  flex-direction: column;
  padding: 3rem 1rem;
  background-color: #f3f3f8;
  min-height: calc(100vh - 72px);
  @media ${tablet} {
    background-color: #fff;
    min-height: calc(100vh - 62px);
    padding: 0;
  }
`;

const Title = styled(Text)`
  color: #28293d;
  text-align: center;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2rem;
  width: 100%;
  padding-bottom: 2.25rem;
  @media ${tablet} {
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-bottom: 1.5rem;
  }
`;

const Form = styled.form`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding: 2.25rem;
  border-radius: 1.25rem;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
  width: 100%;
  padding: 2.25rem;
  max-width: 28.75rem;
  border-radius: 1.5rem;
  background: #fff;
  @media ${tablet} {
    padding: 1.5rem 1rem;
    box-shadow: none;
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

const UserEmail = styled(Text)`
  color: #6a6a6a;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  padding: 0.75rem 0 1rem;
`;

const Label = styled(Text)`
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem;
`;

const Error = styled.div<{ isValid?: boolean; hasErrors?: boolean }>`
  padding-top: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  svg {
    path {
      stroke: ${({ hasErrors, isValid }) =>
        hasErrors ? '#E0494D' : isValid ? '#00B57A' : '#4D4D4D'};
    }
  }
`;

const ErrorText = styled(Text)<{ isValid?: boolean; hasErrors?: boolean }>`
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
  color: ${({ hasErrors, isValid }) =>
    hasErrors ? '#E0494D' : isValid ? '#00B57A' : '#4D4D4D'};
`;

const Input = styled.input<InputProps>`
  outline: none;
  width: 100%;
  color: #1c1c28;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  height: 3rem;
  padding: 0.75rem 1rem;
  border: 1px solid
    ${({ hasError, isValid }) =>
      hasError ? '#E0494D' : isValid ? '#00B57A' : '#e4e4eb'};
  border-radius: 6.25rem;
  background: #fbfbff;
  ::placeholder {
    color: #555770;
  }
`;

const ToggleButton = styled.button`
  position: absolute;
  right: 10px;
  top: 61%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
`;

const InputContainer = styled.div`
  position: relative;
  padding-top: 0.5rem;
`;
